import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const { name = 'video' } = p

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Videók törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',

            [ns + 'SortRelevance']: 'Relevancia',

            ['cancelAbortUpload' + Ns + 'Text']: 'Mégsem',
            ['abortUpload' + Ns + 'Text']: 'Megszakítás',
            ['upload' + Ns + 'HelperText']: 'Kattints vagy húzd ide a videókat',
            ['upload' + N + 'HelperText']: 'Kattints vagy húzd ide a videót',

            [ns + 'OrSelectFromListOpenLabel']: 'Médiatár'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Videók',
            ['my' + Ns + 'Menu']: 'Videóim',
            ['new' + N + 'Menu']: 'Videók feltöltése',
            [ns + 'Menu']: 'Videók',
            ['deleted' + Ns + 'Menu']: 'Törölt videók',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt videók',
            ['protection' + Ns + 'Menu']: 'Védett videók',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + Ns + 'SuccessMessage']: 'Videók feltöltve',
            ['save' + N + 'SuccessMessage']: 'A videó mentésre került',
            ['delete' + N + 'Success']: 'A videó törölve lett',
            ['delete' + Ns + 'Success']: 'A videók törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a videót?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a videókat?',
            ['ban' + N + 'Success']: 'A videó véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a videót véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A videó jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a videót',
            ['protection' + N + 'Success']: 'A videó védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a videót?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek videók',

            ['abortUpload' + Ns + 'Question']: 'Megszkítod a feltöltést?',
            ['abortUpload' + Ns + 'Success']: 'Feltöltés megszakítva',
            ['new' + Ns + 'ClientErrorMessage']: 'Hiba történt a videó megnyitásakor, próbáld újratölteni az oldalt'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Videó',
            [ns + 'Title']: 'Videók',
            ['new' + N + 'Title']: 'Új videó',
            ['edit' + N + 'Title']: 'Videó szerkesztése',
            ['user' + Ns + 'Title']: 'Videók',
            ['userDeleted' + Ns + 'Title']: 'Törölt videók',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt videók',
            ['my' + Ns + 'Title']: 'Videóim',
            ['myDeleted' + Ns + 'Title']: 'Törölt videóim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt videóim',

            ['dialogDelete' + N + 'Title']: 'Videó törlése',
            ['dialogBan' + N + 'Title']: 'Videó törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Videó jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Videó védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Videó védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Videók törlése',
            ['dialogAbortUpload' + Ns + 'Title']: 'Feltöltés megszakítása',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt videók',
            ['deleted' + Ns + 'Title']: 'Törölt videók',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett videók',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Videók kiválasztása',
            ['select' + N]: 'Videó kiválasztása'
        }
    }
}
