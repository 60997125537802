import React, { useContext } from 'react'

import AppContext from '../../components/App/context'
import InfoBox from '../../components/InfoBox'

import { getPostTypeConfig as getFilePostTypeConfig } from '../image'

import getConstants from './constants'
import Content from './Content'

function getPostTypeConfig() {
    const r = getFilePostTypeConfig({ name: 'referenceLetter', getConstants })
    return {
        ...r,
        userPageFunctions: (p) => {
            return {
                ...r.userPageFunctions({
                    ...p,
                    nsPageProps: {
                        type: 'list',
                        BeforePostsComponent: ()=>{

                            const name = 'referenceLetter'

                            const appContext = useContext(AppContext)

                            return (
                                <InfoBox
                                    title={appContext.labels[name + 'ListInfoTitle']}
                                    content={appContext.messages[name + 'ListInfoContent']}
                                />
                            )
                        }
                    }
                }),
                addMenuItems: () => [],
            }
        },
        getComponent: (p) => {
            return r.getComponent({
                ...p, pages: {
                    content: Content
                }
            })
        }
    }
}

export default getPostTypeConfig()
