import React from 'react'

import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'

import BellIcon from 'vlm-ui/dist/common/src/svg/BellIcon'

import { defaultGetPageName } from '../../components/Post/utils'
import { CustomMenuItem } from '../../components/Posts/Components/CustomMenuItem'
import { getPostOrRequest, updateCache } from '../../components/Post/Edit/updateCache'

import defaultPostTypeConfig from '../post'

import getConstants from './constants'
import getMenu, { getMenuProps } from './menu'

import { tableComponents, tableIcons } from './tableComponents'

import { StartIconAvatar, StartIconCredit } from './StartIcon'
import capitalize from '../../utils/capitalize'

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    secondaryPostId: { type: String }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const name = 'notification'

        const { wapp } = p

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit'
            ]
        })

        const contentProps = wapp.contents.get(name)

        wapp.contents.add({
            [name]: {
                ...contentProps,
                robots: () => {
                    return 'noindex,nofollow'
                }
            }
        })

        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name: 'notification'
        })
    },
    getComponent: function(p) {

        const name = 'notification'

        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
        const Ns = capitalize(ns)

        const { context, appContext } = p
        const { routes } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        const isAdmin = user && user._status_isFeatured

        if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

            const pages = {}

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            const requestKeys = [name + 'FindById']
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)
            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))

            const isAuthorOrAdmin = (isAuthor || isAdmin)

            if (requestPath.startsWith(routes[name + 'Route']) && requestPath !== routes[name + 'Route'] + '/new' && !isAuthorOrAdmin) {
                return null
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    type: 'list',
                    tableProps: {
                        components: tableComponents,
                        icons: tableIcons,
                        showLabelTitle: true
                    },
                    getMenu: (p) => getMenu(p).map((m) => {
                        delete m.featured
                        return m
                    }),
                    selectable: () => false,
                    selectFunctions: [],
                    MenuItemComponent: (props) => {

                        const notificationType = props.itemProps.post?.notificationType
                        const thumb = props.itemProps.post?.thumb
                        const secondaryUser = props.itemProps.post?.secondaryUser
                        const credit = props.itemProps.post?.credit
                        const href = props.itemProps.post?.secondaryPostUrl

                        return <CustomMenuItem
                            {...props}
                            {...props.itemProps.post.paperColor ? { color: props.itemProps.post.paperColor } : {}}
                            {...(notificationType === 'newApply' || notificationType === 'newSubscribeToEmployee') && !thumb && secondaryUser?._id ? {
                                StartIconContainerComponent: (props) => {
                                    return <StartIconAvatar {...props} secondaryUser={secondaryUser} />
                                },
                                startIcon: <></>
                            } : {}}
                            {...notificationType === 'creditsApplied' && !thumb ? {
                                StartIconContainerComponent: (props) => {
                                    return <StartIconCredit {...props} credit={credit} />
                                },
                                startIcon: <></>
                            } : {}}
                            {...href ? { href } : {}}
                        />
                    },
                    getMenuProps: (p) => {
                        return {
                            ...getMenuProps(p),
                            adminList: true
                        }
                    }
                },
                componentProps: {
                    pages,
                    showAuthor: ({ user }) => user?._status_isFeatured,
                    //showCreatedDate: ({ user, author }) => user?._id && user._id === author?._id,
                    getProps: ({ pageName }) => {

                        if (pageName === 'content') {

                            return {
                                showAuthor: () => false,
                                PaperComponentProps: {
                                    color: undefined,
                                    elevation: 0,
                                    style: {
                                        padding: 0
                                    }
                                }
                            }

                        }

                        if (pageName === 'new') {
                            return {
                                pageProps: {
                                    requestProps: {
                                        redirect: {
                                            pathname: appContext.routes.userRoute + '/' + user?._id + appContext.routes['user' + Ns + 'Route'],
                                            search: '',
                                            hash: ''
                                        }
                                    }
                                }
                            }
                        }

                        return {}
                    },
                    getMenu,
                    getMenuProps,
                    getPageName: (props) => {
                        const r = defaultGetPageName(props)
                        if (r === 'content' || (r === 'new' && !isAdmin)) {
                            return null
                        }
                        return r
                    }
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, postContext } = p

        const n = 'notification'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        const N = capitalize(n)
        const Ns = capitalize(ns)

        const { wapp, res, req } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType, _id } = params

        const utils = getUtils(context)

        const user = req.wappRequest.user

        const myList = user?._id && _id === user._id.toString()

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                tableProps: {
                    components: tableComponents,
                    icons: tableIcons,
                    showLabelTitle: true
                },
                getMenu,
                getMenuProps: (p) => {
                    return {
                        ...getMenuProps(p),
                        myList
                    }
                },
                selectable: () => false,
                selectFunctions: [],
                type: 'list',
                MenuItemComponent: (props) => {

                    const notificationType = props.itemProps.post?.notificationType
                    const thumb = props.itemProps.post?.thumb
                    const secondaryUser = props.itemProps.post?.secondaryUser
                    const credit = props.itemProps.post?.credit
                    const href = props.itemProps.post?.secondaryPostUrl

                    return <CustomMenuItem
                        {...props}
                        {...props.itemProps.post.paperColor ? { color: props.itemProps.post.paperColor } : {}}
                        {...(notificationType === 'newApply' || notificationType === 'newSubscribeToEmployee') && !thumb && secondaryUser?._id ? {
                            StartIconContainerComponent: (props) => {
                                return <StartIconAvatar {...props} secondaryUser={secondaryUser} />
                            },
                            startIcon: <></>
                        } : {}}
                        {...notificationType === 'creditsApplied' && !thumb ? {
                            StartIconContainerComponent: (props) => {
                                return <StartIconCredit {...props} credit={credit} />
                            },
                            startIcon: <></>
                        } : {}}
                        {...href ? { href } : {}}
                        onClick={() => {

                            const post = props.itemProps.post

                            const isAuthor = ((user?._id && user?._id === post?._author) || (user?._id && user?._id === post?._author?._id))

                            if (post?._id && (isAuthor || post?._isGlobal)) {

                                async function request() {
                                    const response = await utils.sendRequest({
                                        requestName: n + 'View',
                                        args: {
                                            _id: post?._id
                                        }
                                    })
                                    if (response?.record?._id && response.shouldUpdate) {
                                        const post = await getPostOrRequest({
                                            wapp,
                                            req,
                                            res,
                                            post: response.record,
                                            name: n
                                        })
                                        if (post?._status) {
                                            updateCache({ wapp, res, post })
                                        }
                                    }
                                }

                                request()
                            }

                            const secondaryPostType = post.secondaryPostType;

                            if (secondaryPostType) {
                                res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                                    type: 'INS_RES',
                                    name: 'responses',
                                    value: {
                                        [secondaryPostType + 'FindById']: null
                                    }
                                }))
                            }

                        }}
                    />
                },
                disablePageInfo: true,
                BeforePostsComponent: () => {

                    const isAuthor = (user?._id && user._id === _id)

                    if (isAuthor) {

                        async function request() {
                            await utils.sendRequest({
                                requestName: 'user' + 'ClearUnseenNotificationsNumber',
                                args: {
                                    _id: user._id
                                }
                            })
                        }

                        request()

                    }

                    return null

                }
            }
        })

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext } = props
            const { menus, routes } = appContext

            return [
                {
                    label: function(p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                    },
                    role: function(p) {
                        return (p.user?._id && p.user._status_isFeatured)
                    },
                    startIcon: <BellIcon />,
                    order: 41,
                    items: [
                        {
                            label: menus['new' + N + 'Menu'],
                            href: routes[n + 'Route'] + '/new',
                            role: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                const isAdmin = p.user && p.user._status_isFeatured
                                return !!(isAdmin && isAuthor)
                            },
                            disableParentRoute: true
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                return ((p.user && p.user._status_isFeatured) || (p.post && p.post._status_isFeatured))
                            }
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            label: function() {
                                return menus['deleted' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                            },
                            role: function(p) {
                                const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id))
                                const isAdmin = p.user && p.user._status_isFeatured
                                return isAuthor || isAdmin
                            }
                        }
                    ]
                }
            ]
        }

        function addContentMenuItems() {
            return []
        }

        function getPageName({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAuthorOrAdmin) ? page : 'notFound' : null

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function(p) {

        const name = 'notification'

        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 41

        defaultAdminMenu.items = defaultAdminMenu.items.filter((item, i) => {
            return (i === 0 || i === 1 || i === 2 || i === 3 || i === 6 || i === 7)
        })

        return defaultAdminMenu
    }
}

export default postTypeConfig
