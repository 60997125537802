import React from 'react'

import { defaultComponents } from '../../../../components/Table/defaultComponents'

import OrderStatus from './OrderStatus'
import SzamlazzHuButton from './SzamlazzHuButton'

export const tableIcons = {}

export const tableComponents = {
    OrderStatus: {
        props: {
            value: 10,
            name: 'order'
        },
        Component: OrderStatus
    },
    SzamlazzHuButton: {
        props: {
            ...defaultComponents.Button.props
        },
        Component: SzamlazzHuButton
    }
}
