import React, { lazy, Suspense, useEffect, useState } from 'react'

const Lazy = lazy(() => import('./sync').catch((error) => {
    console.log('[APP] Component Failed Loading:', error)
    return { default: () => <div /> }
}))

export default React.forwardRef((props, ref) => {

    const [userInteract, setUserInteract] = useState(false)

    useEffect(() => {

        function on(e) {
            if (e.isTrusted) {
                if (!userInteract) {
                    setUserInteract(true)
                }
                removeListeners()
            }
        }

        function removeListeners() {
            window.removeEventListener('touchstart', on)
            window.removeEventListener('mousemove', on)
        }

        window.addEventListener('touchstart', on)
        window.addEventListener('mousemove', on)

        return () => {
            removeListeners()
        }

    }, [])

    return (
        <Suspense fallback={<div></div>}>
            {userInteract ? <Lazy {...props} ref={ref} /> : null}
        </Suspense>
    )
})
