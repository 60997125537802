import React, { useContext, useEffect, useRef, useState } from 'react'
import Paper from 'vlm-ui/dist/common/src/components/Paper'
import Image from 'vlm-ui/dist/common/src/components/Image'
import Typography from 'vlm-ui/dist/common/src/components/Typography'
import Button from 'vlm-ui/dist/common/src/components/Button'
import Container from 'vlm-ui/dist/common/src/components/Container'
import AppContext from '../context'

export default function DesktopMenuStaticContent(props) {

    const appContext = useContext(AppContext)

    const {
        classNames
    } = props

    const container = useRef()
    const [images, setImages] = useState({})

    useEffect(() => {
        async function loadImage() {
            if ((!images.girl || !images.chefIllustration)) {
                const girl = await import('../../../images/girlSmall.jpg')
                const chefIllustration = await import('../../../images/chefIllustrationMedium.png')
                if (girl?.default || chefIllustration?.default) {
                    setImages({
                        girl: girl?.default,
                        chefIllustration: chefIllustration?.default
                    })
                }
            }
        }

        loadImage()
    }, [])

    return (
        <Container
            ref={container}
            containerVariant={'parent'}
            className={classNames.megaMenuContainer}
        >
            <div className={classNames.megaMenuContent}>
                <Paper
                    className={classNames.megaMenuContentLeft}
                    color={'paper3'}
                >
                    {images.girl ? <Image
                        src={images.girl}
                        className={classNames.megaMenuContentLeftImage}
                        height={'100%'}
                    /> : null
                    }
                    <Typography variant={'h4'}>
                        {'Add fel álláshirdetésed és találd meg új kollégád egyszerűen'}
                    </Typography>
                    <Button color={'secondary'}
                            label={appContext.menus.loginMenu}
                            href={appContext.routes.accountRoute + '/login'}
                    />
                </Paper>
                <Paper
                    className={classNames.megaMenuContentRight}
                    color={'primary'}
                    variant={'contained'}
                >
                    <div
                        className={classNames.megaMenuContentRightImage}
                    >
                        {images.chefIllustration ?
                            <Image
                                src={images.chefIllustration}
                            /> : null
                        }
                    </div>
                    <Typography variant={'h4'}>
                        {'Regisztrálj munkavállalóként és jelentkezz az állásokra egy kattintással'}
                    </Typography>
                    <Button color={'secondary'}
                            label={appContext.menus.signupMenu}
                            href={appContext.routes.accountRoute + '/signup'}
                    />
                </Paper>
            </div>
        </Container>
    )

}
