import getDefaultMenu, { getMenuProps as defaultGetMenuProps } from '../../components/Post/menu'
import capitalize from '../../utils/capitalize'

export function getMenu(props) {
    return getDefaultMenu(props).map((menu, i) => {

        const name = props.name
        const N = capitalize(name)

        if (menu.label === props.appContext.menus['delete' + N + 'Menu']) {
            return {
                ...menu,
                role: (p) => {
                    const isAdmin = p.user && p.user._status_isFeatured
                    const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id))
                    const isBanned = p.post && p.post._status_isBanned
                    const isFeatured = p.post && p.post._status_isFeatured

                    if (p.user?._id === p.post?._id && !isAdmin) {
                        return false
                    }

                    if ((isBanned && !isAdmin) || isFeatured) {
                        return false
                    }

                    if (isAdmin || isAuthor) {
                        const isNotDeleted = p.post && p.post._status_isNotDeleted
                        return !!(p.post?._id && isNotDeleted && (p.page === 'edit' || !p.page))
                    }

                    return false
                }
            }
        }

        if (menu.label === props.appContext.menus['edit' + N + 'Menu'] && i === 0) {
            return {
                ...menu,
                role: (p) => {
                    const isAdmin = p.user && p.user._status_isFeatured
                    const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id))
                    const isBanned = p.post && p.post._status_isBanned
                    const isFeatured = p.post && p.post._status_isFeatured

                    if (p.user?._id === p.post?._id && !isAdmin) {
                        return false
                    }

                    if ((isBanned && !isAdmin) || isFeatured) {
                        return false
                    }

                    if (isAdmin || isAuthor) {
                        return !!(p.post?._id && p.isList)
                    }
                    return false
                }
            }
        }

        return null
    }).filter((menu) => menu)
}

export function getMenuProps({ wapp, res, onSubmit, name, ...p }) {
    return {
        ...defaultGetMenuProps({
            ...p,
            name,
            onSubmit: (e, response, n) => {
                if (n === name + 'Delete') {
                    if (response?.record?._id) {
                        res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                            type: 'INS_RES',
                            name: 'responses',
                            value: {
                                jobFindById: null
                            }
                        }))
                    }
                }
                if (onSubmit) {
                    onSubmit(e, response, n)
                }
            }
        })
    }
}
