import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'subscribe'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Feliratkozom',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Feliratkozások törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },

            [n + 'SzakacsokProfessionAll']: 'Összes szakács állás',
            [n + 'KiszolgalokProfessionAll']: 'Összes kiszolgáló állás',
            [n + 'KisegitokProfessionAll']: 'Összes kisegítő állás',
            [n + 'VezetokProfessionAll']: 'Összes vezető állás',
            [n + 'SzallitokProfessionAll']: 'Összes szállító állás',
            [n + 'SzervezokProfessionAll']: 'Összes szervező állás',
            [n + 'Tovabbi-szakemberekProfessionAll']: 'Összes további állás',

            ['moreFromMy' + Ns + 'Title']: 'További feliratkozásaim',
            ['moreFromUser' + Ns + 'Title']: 'További feliratkozások',

            [n + 'SubscribeInfoTitle']: (userName) => 'Biztos, hogy feliratkozol ' + (userName || 'a felhasználó') + ' hirdetéseire?',
            [n + 'SubscribeEditInfoTitle']: (userName) => 'Feliratkoztál ' + (userName || 'a felhasználó') + ' hirdetéseire',
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Feliratkozások',
            ['my' + Ns + 'Menu']: 'Feliratkozásaim',
            ['new' + N + 'Menu']: 'Új feliratkozás',
            [ns + 'Menu']: 'Feliratkozások',
            ['deleted' + Ns + 'Menu']: 'Törölt feliratkozások',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt feliratkozások',
            ['protection' + Ns + 'Menu']: 'Védett feliratkozások',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'A feliratkozás mentésre került',
            ['save' + N + 'SuccessMessage']: 'A feliratkozás mentésre került',
            ['delete' + N + 'Success']: 'A feliratkozás törölve lett',
            ['delete' + Ns + 'Success']: 'A feliratkozások törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a feliratkozást?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a feliratkozásoket?',
            ['ban' + N + 'Success']: 'A feliratkozás véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a feliratkozást véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A feliratkozás jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a feliratkozást',
            ['protection' + N + 'Success']: 'A feliratkozás védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a feliratkozást?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek feliratkozások',

            [n + 'SubscribeInfoContent']: (userName) => 'Ha igen ez azt jelenti, hogy amikor ' + (userName || 'a felhasználó') + ' egy új hirdetést ad fel, akkor neked értesítést küldünk róla emailben. Válaszd ki a szakmákat, amik érdekelnek téged ' + (userName || 'a felhasználó') + ' hirdetései közül!',
            [n + 'SubscribeEditInfoContent']: (userName) => 'Ez azt jelenti, hogy amikor ' + (userName || 'a felhasználó') + ' egy új hirdetést ad fel, akkor neked értesítést küldünk róla emailben. Válaszd ki a szakmákat, amik érdekelnek téged ' + (userName || 'a felhasználó') + ' hirdetései közül!'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Feliratkozás',
            [ns + 'Title']: 'Feliratkozások',
            ['new' + N + 'Title']: 'Feliratkozás',
            ['edit' + N + 'Title']: 'Feliratkozás szerkesztése',
            ['user' + Ns + 'Title']: 'Feliratkozások',
            ['userDeleted' + Ns + 'Title']: 'Törölt feliratkozások',
            ['my' + Ns + 'Title']: 'Feliratkozásaim',
            ['myDeleted' + Ns + 'Title']: 'Törölt feliratkozásaim',

            ['dialogDelete' + N + 'Title']: 'Feliratkozás törlése',
            ['dialogBan' + N + 'Title']: 'Feliratkozás törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Feliratkozás jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Feliratkozás védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Feliratkozás védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Feliratkozások törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt feliratkozások',
            ['deleted' + Ns + 'Title']: 'Törölt feliratkozások',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett feliratkozások',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Feliratkozások kiválasztása',
            ['select' + N]: 'Feliratkozás kiválasztása'
        }
    }
}
