import React from 'react'
import { getPostTypeConfig as getFilePostTypeConfig } from '../image'

import getConstants from './constants'

function getPostTypeConfig() {
    const r = getFilePostTypeConfig({ name: 'video', getConstants })
    return {
        ...r,
        userPageFunctions: (p) => {
            return {
                ...r.userPageFunctions(p),
                addMenuItems: ()=>[]
            }
        }
    }
}

export default getPostTypeConfig()
