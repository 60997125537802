import React from 'react'

import DefaultArchiveComponent from '../post/DefaultArchiveComponent'
import DefaultComponent from './DefaultComponent'

export default function getComponent(p) {

    const { context, appContext, name = 'user' } = p
    const { routes } = appContext

    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

    const { res, req } = context
    const route = res.wappResponse.route
    //const { params } = route
    //const { _id } = params
    const requestPath = route.requestPath
    const user = req.wappRequest.user

    const isAdmin = user && user._status_isFeatured
    //const isAuthor = ((user?._id && user._id.toString() === _id))
    //const isAuthorOrAdmin = (isAuthor || isAdmin)

    if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
        return null
    }

    if (requestPath.startsWith(routes['employeesRoute']) && !isAdmin) {
        return null
    }

    const {
        archiveComponentProps = {
            disableAvatars: true,
            selectable: false,
            tableProps: {
                showLabelTitle: true
            }
        },
        componentProps = {},
        Component = DefaultComponent,
        ArchiveComponent = DefaultArchiveComponent,
        ...rest
    } = p

    return (
        requestPath.startsWith(routes[ns + 'Route']) ||
        requestPath.startsWith(routes['employeesRoute'])
    ) ?
        (props) =>
            <ArchiveComponent
                {...rest}
                {...archiveComponentProps}
                {...props}
                MenuItemProps={{
                    ContentComponentProps:{
                        ImageProps: {
                            ComponentProps: {
                                style: {
                                    aspectRatio: '1/1',
                                    width: 'auto',
                                    height: '100%',
                                }
                            }
                        }
                    }
                }}
                name={name}
            /> :
        (requestPath.startsWith(routes[name + 'Route'])) ?
            (props) => {

                const cProps = {
                    ...rest,
                    ...componentProps,
                    ...props
                }

                return (
                    <Component
                        {...{
                            getProps: ({ pageName, post, user }) => {

                                const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
                                const isAdmin = user && user._status_isFeatured

                                if (pageName === 'content' && !post?.activeJobs && !post?.profile?._author && !(isAuthor || isAdmin)) {
                                    return {
                                        PaperComponentProps: {
                                            color: undefined,
                                            elevation: 0,
                                            style: {
                                                padding: 0
                                            }
                                        }
                                    }
                                }
                                return {}
                            }
                        }}
                        {...cProps}
                        name={name}
                    />
                )

            }
            : null
}
