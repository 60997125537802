import React from 'react'

export const tableIcons = {}

export const tableComponents = {
    PaymentStatus: {
        props: {
            value: 20,
            options: []
        },
        Component: (props) => {
            const { options } = props
            const foundOrderStatusOption = options && options.find(({ value }) => value === props.value)
            const statusText = foundOrderStatusOption?.label

            if (statusText) {
                return (
                    <div>
                        {statusText}
                    </div>
                )
            }

            return null
        }
    }
}
