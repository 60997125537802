import React from 'react'

import Typography from 'vlm-ui/dist/common/src/components/Typography'
import Label from 'vlm-ui/dist/common/src/components/Label'
import StarIcon from 'vlm-ui/dist/common/src/svg/StarIcon'
import Paper from 'vlm-ui/dist/common/src/components/Paper'
import { Divider } from 'vlm-ui/dist/common/src/components/MenuItem'
import DoneIcon from 'vlm-ui/dist/common/src/svg/DoneIcon'

export function transformPosts({ posts, classNames }) {

    return posts.map((post) => {

        const { priceNetText, title, vatRateText, discountText, infoText, featuredChildren, ...rest } = post

        const creditSplit = title.split(' ')
        const credit = creditSplit.slice(0, -1).join(' ')
        const creditCurrency = title.split(credit)[1]

        return (
            {
                _original: () => {
                    return {
                        priceNetText,
                        title,
                        vatRateText,
                        discountText,
                        infoText,
                        featuredChildren,
                        ...rest
                    }
                },
                ...rest,
                title: <div className={classNames.pricesItemTitle}>
                    <Typography variant={'h2'}>{credit}</Typography>
                    <Typography variant={'h6'}>{creditCurrency}</Typography>
                </div>,
                subtitle: <>
                    <div className={classNames.pricesHeadingLabelContainer}>
                        <Typography variant={'h6'}>{priceNetText}</Typography>
                        {vatRateText ? <Typography variant='subtitle1'
                                                   weight={600}>{'+' + vatRateText}</Typography> : null}
                    </div>
                </>,
                contentExtract: <>
                    {featuredChildren?.length ?
                        <div className={classNames.pricesFeatured}>
                            {featuredChildren.map((menu, i) => <Label
                                key={i}
                                label={menu.label}
                                color={menu.color}
                                startIcon={<StarIcon />}
                            />)
                            }
                        </div> : null
                    }
                    {discountText ?
                        <Paper
                            color={'featured'}
                            variant={'text'}
                            size={'none'}
                            elevation={0}
                        >
                            <Typography variant={'h6'}
                                        weight={600}>{discountText}</Typography>
                        </Paper> : null
                    }
                    {infoText && false ?
                        <>
                            <Divider className={classNames.pricesItemDivider} />
                            <Label
                                size={'label1'}
                                TypographyProps={{ weight: 500 }}
                                startIcon={
                                    <div>
                                        <Paper
                                            color={'primary'}
                                            borderRadius={'50%'}
                                            size={'none'}
                                            variant={'contained'}
                                            className={classNames.customDoneIcon}
                                        >
                                            <DoneIcon />
                                        </Paper>
                                    </div>
                                }
                            >
                                {infoText}
                            </Label>
                        </>
                        : null
                    }
                </>,
                MenuItemProps: {
                    enableDivider: false,
                    ContentComponentProps: {
                        ContentComponentProps: {
                            style: {
                                position: 'unset'
                            }
                        }
                    }
                }
            }
        )
    })
}
