import getDefaultMenu, { getMenuProps as defaultGetMenuProps } from '../../components/Post/menu'
import capitalize from '../../utils/capitalize'

export function getMenuProps(p) {
    return {
        ...defaultGetMenuProps(p),
        onDelete: async (e) => {

            const response = await p.utils.sendRequest({
                requestName: p.name + 'Delete',
                args: { _id: p.post?._id },
                timeOut: 1000,
                ...p.requestProps?.onDeleteSuccess ? p.requestProps.onDeleteSuccess : {}
            })

            if (p.onSubmit) {
                await p.onSubmit(e, response, p.name + 'Delete')
            }

            return response

        }
    }
}

function getMenu(props = {}) {
    return getDefaultMenu(props).map((menu) => {

        const name = props.name
        const N = capitalize(name)

        if (menu.label === props.appContext.menus['delete' + N + 'Menu'] || menu.label === props.appContext.menus['ban' + N + 'Menu']) {
            return {
                ...menu,
                role: (p) => {
                    const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id))

                    if (isAuthor && !(props.myList || props.adminList)) {
                        return false
                    }

                    return menu.role(p)
                }
            }
        }

        return null
    }).filter((menu) => menu)
}

export default getMenu
