import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'profile'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Profil mentése',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Profil mentése',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Profilok törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['moreFromMy' + Ns + 'Title']: 'További profiljaim',
            ['moreFromUser' + Ns + 'Title']: 'További profilok',
            [n + 'ContactBox']: 'További közösségi oldalak',
            [n + 'OpenContactBox']: 'Szerkesztés',

            [ns + 'SortRelevance']: 'Relevancia',
            [n + 'NewProfileInfoClose']: 'Rendben'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Profilok',
            ['my' + Ns + 'Menu']: 'Profiljaim',
            ['new' + N + 'Menu']: 'Profil létrehozása',
            [ns + 'Menu']: 'Profilok',
            ['deleted' + Ns + 'Menu']: 'Törölt profilok',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt profilok',
            ['protection' + Ns + 'Menu']: 'Védett profilok',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',
            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése',

            ['my' + N + 'Menu']: 'Munkavállalói profil'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Profilodat rögzítettük',
            ['save' + N + 'SuccessMessage']: 'A profil mentésre került',
            ['delete' + N + 'Success']: 'A profil törölve lett',
            ['delete' + Ns + 'Success']: 'A profilok törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli az profilt?',
            ['delete' + Ns + 'Question']: 'Biztosan törli az profilokat?',
            ['ban' + N + 'Success']: 'A profil véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy az profilt véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A profil jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod az profilt',
            ['protection' + N + 'Success']: 'A profil védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd az profilt?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek profilok',
            [n + 'NewProfileInfo']: 'Ezt az oldalt akkor töltsd ki, ha szeretnéd, hogy a kiválasztás folyamatánál nagyobb eséllyel hívjanak be egy próbanapra. Csak az láthatja a munkavállalói profilodat, akinek elküldted a jelentkezés gomb megnyomásával a jelentkezésedet.'

        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Munkavállalói profil',
            [ns + 'Title']: 'Profilok',
            ['new' + N + 'Title']: 'Munkavállalói profil létrehozása',
            ['edit' + N + 'Title']: 'Munkavállalói profil szerkesztése',
            ['user' + Ns + 'Title']: 'Profilok',
            ['userDeleted' + Ns + 'Title']: 'Törölt profilok',
            ['userBanned' + Ns + 'Title']: 'Véglegesen törölt profilok',
            ['my' + Ns + 'Title']: 'Profiljaim',
            ['myDeleted' + Ns + 'Title']: 'Törölt profiljaim',
            ['myBanned' + Ns + 'Title']: 'Véglegesen törölt profiljaim',

            ['dialogDelete' + N + 'Title']: 'Profil törlése',
            ['dialogBan' + N + 'Title']: 'Profil törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Profil jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Profil védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Profil védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Profilok törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt profilok',
            ['deleted' + Ns + 'Title']: 'Törölt profilok',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett profilok',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Profilok kiválasztása',
            ['select' + N]: 'Profil kiválasztása'

        }
    }
}
