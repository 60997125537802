import React, { lazy, Suspense } from 'react'

const LazyStartIconAvatar = lazy(() => import('./StartIconAvatarSync').catch((error) => {
    console.log('[APP] Component Failed Loading:', error)
    return { default: () => <div /> }
}))

export const StartIconAvatar = React.forwardRef((props, ref) => {
    return (
        <Suspense fallback={<div></div>}>
            <LazyStartIconAvatar {...props} ref={ref} />
        </Suspense>
    )
})

const LazyStartIconCredit = lazy(() => import('./StartIconCreditSync').catch((error) => {
    console.log('[APP] Component Failed Loading:', error)
    return { default: () => <div /> }
}))

export const StartIconCredit = React.forwardRef((props, ref) => {
    return (
        <Suspense fallback={<div></div>}>
            <LazyStartIconCredit {...props} ref={ref} />
        </Suspense>
    )
})
