export function getPageName({ user, page }) {

    function renderWithUser() {
        if (!page) {
            return 'settings'
        }
        if ([
            'settings',
            'forgotpassword',
            'resetpassword',
            'changedata',
            'invoicesettings',
            'employeesettings',
            'notificationsettings',
            'changeemail',
            'changepassword',
            'emailconfirmation',
            'logout',
            'login',
            'signup',
            'deleteaccount',
            'profilesettings',
            'othersettings'
        ].indexOf(page) > -1) {
            if (page === 'login' || page === 'signup') {
                return 'settings'
            }
            return page
        }
        return null
    }

    function renderWithoutUser() {
        if (!page) {
            return 'login'
        }
        if (['login', 'signup', 'forgotpassword', 'resetpassword', 'emailconfirmation'].indexOf(page) > -1) {
            return page
        }
        return null
    }

    return (!user) ? renderWithoutUser() : renderWithUser()

}

export function getTitle({ user, page, titles }) {

    const pageName = getPageName({ user, page })

    if (pageName === 'login') {
        return titles.loginTitle
    }

    if (pageName === 'signup') {
        return titles.signupTitle
    }

    if (pageName === 'changedata') {
        return titles.changeDataTitle
    }

    if (pageName === 'invoicesettings') {
        return titles.invoiceSettingsTitle
    }

    if (pageName === 'employeesettings') {
        return titles.employeeSettingsTitle
    }

    if (pageName === 'notificationsettings') {
        return titles.notificationSettingsTitle
    }

    if (pageName === 'changeemail') {
        return titles.changeEmailTitle
    }

    if (pageName === 'logout') {
        return titles.logoutTitle
    }

    if (pageName === 'resetpassword') {
        return titles.resetPasswordTitle
    }

    if (pageName === 'forgotpassword') {
        return titles.forgotPasswordTitle
    }

    if (pageName === 'changepassword') {
        return titles.changePasswordTitle
    }

    if (pageName === 'emailconfirmation') {
        return titles.emailConfirmationTitle
    }

    if (pageName === 'deleteaccount') {
        return titles.deleteAccountTitle
    }

    if (pageName === 'settings') {
        return titles.accountTitle
    }

    if (pageName === 'profilesettings') {
        return titles.profileSettingsTitle
    }

    if (pageName === 'othersettings') {
        return titles.otherSettingsTitle
    }

    return ''
}
