import React, { lazy, Suspense, useContext, useEffect, useRef, useState } from 'react'
import { WappContext } from 'wapplr-react/dist/common/Wapp'

const Lazy = lazy(() => import('./sync').catch((error) => {
    console.log('[APP] Component Failed Loading:', error)
    return { default: () => <div /> }
}))

export default (props) => {

    const context = useContext(WappContext)
    const { res } = context

    const reference = useRef()
    const listener = useRef({})

    const history = res.wappResponse.store.getState('app.history') || []
    const lastItem = history[history.length - 1]
    const [show, setShow] = useState((lastItem.key !== 'initial' && history.length > 1))

    async function onIntersected(e, value) {
        if (!show && value) {
            setShow(true)
            if (listener.current.remove) {
                listener.current.remove()
                listener.current.remove = null
            }
        }
    }

    function addIntersectionListeners() {
        if (reference.current && typeof IntersectionObserver !== 'undefined') {
            const resizeObserver = new IntersectionObserver(
                ([e]) => {
                    onIntersected(null, !e.intersectionRatio < 1)
                },
                { threshold: [0] }
            )
            resizeObserver.observe(reference.current)
            return function removeEventListener() {
                resizeObserver.disconnect()
            }
        }
    }

    useEffect(() => {
        if (listener.current.remove) {
            listener.current.remove()
            listener.current.remove = null
        }
        const removeIntersectionListeners = addIntersectionListeners()
        if (listener.current.remove) {
            listener.current.remove = removeIntersectionListeners
        }
        return () => {
            if (removeIntersectionListeners) {
                removeIntersectionListeners()
            }
        }
    }, [])

    return (
        <>
            {!show ? <div ref={reference} style={{ width: '1px', height: '1px' }}></div> : null}
            <Suspense fallback={<div></div>}>
                {show ? <Lazy {...props} /> : null}
            </Suspense>
        </>
    )
}
