import React, { lazy, Suspense } from 'react'

const Lazy = lazy(() => import('./FeaturedFormSync').catch((error) => {
    console.log('[APP] Component Failed Loading:', error)
    return { default: () => <div /> }
}))

export default React.forwardRef((props, ref) => {
    return (
        <Suspense fallback={<div></div>}>
            <Lazy {...props} ref={ref} />
        </Suspense>
    )
})
