export function updateCache({ wapp, res, post }) {

    function _updateCache(cache) {
        let shouldUpdate = false
        Object.keys(cache).forEach((key) => {
            const isList = cache[key]?.items
            if (isList) {
                cache[key].items.forEach((item, i) => {
                    if (item._id === post._id && JSON.stringify(item) !== JSON.stringify(post)) {
                        cache[key].items[i] = { ...post }
                        shouldUpdate = true
                        if (wapp.globals.DEV) {
                            console.log('[APP] Updating cache for post...', post._id, key)
                        }
                    }
                })
            } else if (cache[key]?._id === post._id && JSON.stringify(cache[key]) !== JSON.stringify(post)) {
                cache[key] = { ...post }
                shouldUpdate = true
                if (wapp.globals.DEV) {
                    console.log('[APP] Updating cache for post...', post._id, key)
                }
            }
        })
        return shouldUpdate
    }

    const cache = res.wappResponse.store.getState('res.cache')

    if (cache) {
        const shouldUpdate = _updateCache(cache)
        if (shouldUpdate) {
            res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                type: 'SET_RES',
                name: 'cache',
                value: cache
            }))
        }
    }

    const responses = res.wappResponse.store.getState('res.responses')

    if (responses) {
        const shouldUpdate = _updateCache(responses)
        if (shouldUpdate) {
            res.wappResponse.store.dispatch(wapp.states.stateManager.actions.res({
                type: 'SET_RES',
                name: 'responses',
                value: responses
            }))
        }
    }

}

export async function getPostOrRequest(props) {

    const { name = 'post', wapp, req, res } = props
    let post = props.post

    const postId = post?._id || post
    post = post?._status ? post : null

    if (!post) {
        let response = await wapp.requests.send({ requestName: name + 'FindById', req, res, args: { _id: postId } })
        response = response[name + 'FindById'] || response
        if (response._id === postId) {
            post = response
        }
    }

    return post

}
