import React from 'react'

import GlobalType from './GlobalType'

export const tableIcons = {}

export const tableComponents = {
    GlobalType: {
        props: {
            value: 10,
            name: 'order'
        },
        Component: GlobalType
    }
}
