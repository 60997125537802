import getDefaultMenu from '../../components/Post/menu'

function getMenu(props = {}) {
    return getDefaultMenu(props).map((menu, i) => {
        if ((i === 0 || i === 1) && false) {
            const tempRole = menu.role
            menu.role = (p) => {
                const orderIsStarted = p.post?.orderStatus > 10
                const postIsDeleted = !p.post?._status_isNotDeleted
                const isAdmin = p.user?._status_isFeatured
                if (
                    (!isAdmin && orderIsStarted && i === 0) ||
                    (!isAdmin && postIsDeleted && i === 0) ||
                    (!isAdmin && i === 1)
                ) {
                    return false
                }
                return tempRole(p)
            }
            return menu
        } else {
            return null
        }
    }).filter((menu) => menu)
}

export default getMenu
