import React from 'react'

import { defaultGetPageName } from '../../components/Post/utils'

import Menu from 'vlm-ui/dist/common/src/components/Menu'
import ProfileIcon from 'vlm-ui/dist/common/src/svg/ProfileIcon'

import capitalize from '../../utils/capitalize'

import defaultPostTypeConfig from '../post'
import getConstants from './constants'

import { CustomMenuItem } from '../../components/Posts/Components/CustomMenuItem'
import defaultGetMenu, { getMenuProps as defaultGetMenuProps } from '../../components/Post/menu'

import Content from './Content'
import New from './New'
import Edit from './Edit'
import Overview from './Overview'

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    employeeLastName: { type: String },
                    employeeFirstName: { type: String },
                    content: { type: String }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {
        const { wapp } = p
        const name = 'profile'

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name
        })

        wapp.contents.add({
            [name]: {
                ...wapp.contents.get(name),
                robots: () => {
                    return 'noindex,nofollow'
                }
            }
        })
        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {

        const { name = 'profile' } = p

        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name
        })
    },
    getComponent: function(p) {

        const name = 'profile'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes /*userStatusManager*/ } = appContext

        const { wapp, res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        if ((requestPath.startsWith(routes[name + 'Route'])) || (requestPath.startsWith(routes[ns + 'Route']))) {

            const pages = {
                content: Content,
                new: New,
                edit: Edit
            }

            const isAdmin = user && user._status_isFeatured

            const getPageName = ({ page, ...rest }) => {
                return defaultGetPageName({ page, ...rest })
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                return null
            }

            const requestKeys = [name + 'FindById']
            const post = requestKeys.map((requestName) => res.wappResponse.store.getState('res.responses.' + requestName)).find((r) => r?._id)
            const isAuthor = ((user?._id && user._id.toString() === post?._author) || (user?._id && user._id.toString() === post?._author?._id))

            const isAuthorOrAdmin = (isAuthor || isAdmin)

            if (requestPath.startsWith(routes[name + 'Route']) && requestPath !== routes[name + 'Route'] + '/new' && !isAuthorOrAdmin) {
                return null
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                componentProps: {
                    getPageName,
                    showAuthor: () => false,
                    getMenu: (props) => {
                        const menu = defaultGetMenu(props)
                        menu[1].role = (p) => {
                            const isAdmin = p.user && p.user._status_isFeatured
                            const isAuthor = ((p.user?._id && p.user._id === p.post?._author) || (p.user?._id && p.user._id === p.post?._author?._id))
                            const isBanned = p.post && p.post._status_isBanned
                            const isFeatured = p.post && p.post._status_isFeatured

                            if (p.user?._id === p.post?._id && !isAdmin) {
                                return false
                            }

                            if ((isBanned && !isAdmin) || isFeatured) {
                                return false
                            }

                            if (isAdmin || isAuthor) {
                                const isNotDeleted = p.post && p.post._status_isNotDeleted
                                return !!(p.post?._id && isNotDeleted && (p.page === 'content' || !p.page))
                            }

                            return false
                        }
                        return [
                            ...menu
                        ]
                    },
                    getProps: ({ pageName }) => {
                        if (pageName === 'content') {
                            return {
                                layoutType: 'sidebar',
                                PaperComponentProps: {
                                    color: undefined,
                                    elevation: 0,
                                    style: {
                                        padding: 0
                                    }
                                },
                                getTitle: ({ post }) => post._title
                                //disableTitleContainer: true
                            }
                        }
                        return {}
                    },
                    getMenuProps: (props) => {
                        const onSubmit = props.onSubmit
                        return {
                            ...defaultGetMenuProps({
                                ...props,
                                onSubmit: async (...attr) => {
                                    if (onSubmit) {
                                        await onSubmit(...attr)
                                    }
                                    const [_, response] = attr
                                    if (response?.record?._id) {
                                        let newUser = await props.utils.sendRequest({
                                            requestName: 'userFindById',
                                            args: { _id: response.record._author?._id || response.record._author }
                                        })
                                        if (newUser && newUser['userFindById']) {
                                            newUser = newUser['userFindById']
                                        }
                                        if (newUser?._id && newUser._id === user?._id) {
                                            res.wappResponse.store.dispatch(wapp.states.runAction('req', {
                                                name: 'user',
                                                value: { ...newUser }
                                            }))
                                        }
                                    }
                                },
                                requestProps: {
                                    ...props.requestProps ? props.requestProps : {},
                                    onDeleteSuccess: {
                                        redirect: post?._author ? appContext.routes.userRoute + '/' + (post._author._id || post?._author) : null
                                    },
                                    onBanSuccess: {
                                        redirect: post?._author ? appContext.routes.userRoute + '/' + (post._author._id || post?._author) : null
                                    }
                                }
                            })
                        }
                    },
                    pages
                },
                archiveComponentProps: {
                    selectable: false,
                    type: 'list'
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, postContext } = p

        const n = 'profile'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        const N = capitalize(n)
        const Ns = capitalize(ns)

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                selectable: false,
                type: 'list',
                disableAvatars: false,
                MenuItemComponent: (props) => {
                    return <CustomMenuItem
                        {...props}
                        AvatarProps={{
                            href: null
                        }}
                    />
                },
                ...p.nsPageProps ? p.nsPageProps : {}
            }
        })

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext /*statusManager*/ } = props
            const { menus, routes /*userStatusManager*/ } = appContext

            return [
                {
                    label: menus['new' + N + 'Menu'],
                    href: routes[n + 'Route'] + '/new',
                    role: function(p) {
                        return ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id)) && !p.post?.profile?._id
                    },
                    disableParentRoute: true,
                    startIcon: <ProfileIcon />,
                    order: 5
                },
                {
                    label: menus['my' + N + 'Menu'],
                    href: (p) => {
                        return routes[n + 'Route'] + '/' + p.post?.profile?._id
                    },
                    role: function(p) {
                        const viewerIsAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        const viewerIsAdmin = (p.user?._id && p.user._status_isFeatured)
                        return (viewerIsAuthor || viewerIsAdmin) && p.post.profile?._id
                    },
                    disableParentRoute: true,
                    startIcon: <ProfileIcon />,
                    order: 5
                },
                {
                    label: menus['user' + Ns + 'Menu'],
                    role: function(p) {
                        return p.user?._status_isFeatured
                    },
                    startIcon: <ProfileIcon />,
                    order: 30,
                    items: [
                        {
                            label: menus[postContext.name + Ns + 'Menu'],
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            label: function() {
                                return menus['deleted' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                            },
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        }
                    ]
                }
            ]
        }

        function addContentMenuItems() {
            return [
                {
                    role: function(p) {
                        const { user, post } = p
                        const isAdmin = user && user._status_isFeatured
                        const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
                        return !isAdmin && !isAuthor && post?.profile?._author
                    },
                    order: 20,
                    Component: Overview
                }
            ]
        }

        function getPageName({ user, post, page /*statusManager*/ }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAuthorOrAdmin) ? page : 'notFound' : null

        }

        function getDashBoardTitle({ user, post, page }) {

            const isAdmin = user && user._status_isFeatured

            if (page === ns && pageType === 'deleted' && !isAdmin) {
                return null
            }

            return r.getDashBoardTitle({ user, post, page })

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName,
            getDashBoardTitle
        }
    },
    adminMenu: function(p) {
        const name = 'profile'
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 30

        const { routes } = p.appContext

        defaultAdminMenu.items = defaultAdminMenu.items.filter((item) => {
            return item.href !== routes[name + 'Route'] + '/new'
        })

        return defaultAdminMenu
    }
}

export default postTypeConfig
