import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'
import priceWithSpaces from '../order/priceWithSpaces'

export default function getConstants(p = {}) {

    const name = 'payment'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Fizetés indítása',
            ['restart' + N + 'SubmitLabel']: 'Fizetés újraindítása',
            ['newOnline' + N + 'SubmitLabel']: 'Online fizetés',
            ['newCash' + N + 'SubmitLabel']: 'Készpénzes fizetés',
            ['newTransfer' + N + 'SubmitLabel']: 'Kifizetve átutalással',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Válassz egy vásárlást',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Fizetések törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },
            [ns + 'SortRelevance']: 'Relevancia',
            [n + 'TitleLabel']: 'Fizetés azonosító',
            [n + 'AuthorLabel']: 'Fizető',
            [n + 'OpenConnectedOrderText']: 'Megnyitás',

            [n + 'BarionInfoTitle']: 'Bankkártyás fizetés Barion rendszerrel',
            [n + 'BarionInfoDesc']: 'A vásárlás gombra kattintva az oldal átirányítódik a Barion fizető oldalára.',
            [n + 'TotalPriceWithCurrency']: (total) => priceWithSpaces(total) + ' Ft',

            [n + 'SearchLabel']: 'Keresés fizetés azonosító alapján'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Fizetések',
            ['my' + Ns + 'Menu']: 'Fizetéseim',
            ['new' + N + 'Menu']: 'Fizetés indítása',
            [ns + 'Menu']: 'Fizetések',
            ['deleted' + Ns + 'Menu']: 'Törölt fizetések',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt fizetések',
            ['protection' + Ns + 'Menu']: 'Védett fizetések',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'A fizetés mentésre került',
            ['save' + N + 'SuccessMessage']: 'A fizetés mentésre került',
            ['delete' + N + 'Success']: 'A fizetés törölve lett',
            ['delete' + Ns + 'Success']: 'A fizetések törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a fizetést?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a fizetéseket?',
            ['ban' + N + 'Success']: 'A fizetés véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a fizetést véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A fizetés jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a fizetést',
            ['protection' + N + 'Success']: 'A fizetés védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a fizetést?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek fizetések',
            [n + 'AutoSubmit']: (s) => s ? 'Átirányítjuk a Barion oldalára ' + s + ' másodperc múlva' : 'Átirányítjuk a Barion oldalára...'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Fizetés',
            [ns + 'Title']: 'Fizetések',
            ['new' + N + 'Title']: 'Fizetés indítása',
            ['edit' + N + 'Title']: 'Fizetés szerkesztése',
            ['user' + Ns + 'Title']: 'Fizetések',
            ['userDeleted' + Ns + 'Title']: 'Törölt fizetések',
            ['my' + Ns + 'Title']: 'Fizetéseim',
            ['myDeleted' + Ns + 'Title']: 'Törölt fizetéseim',

            ['dialogDelete' + N + 'Title']: 'Fizetés törlése',
            ['dialogBan' + N + 'Title']: 'Fizetés törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Fizetés jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Fizetés védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Fizetés védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Fizetések törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt fizetések',
            ['deleted' + Ns + 'Title']: 'Törölt fizetések',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett fizetések',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Fizetések kiválasztása',
            ['select' + N]: 'Fizetés kiválasztása'
        }
    }
}
