import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'method'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Mentés',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Mentés',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Metódusok törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },
            [ns + 'SortRelevance']: 'Relevancia'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Metódusok',
            ['my' + Ns + 'Menu']: 'Metódusaim',
            ['new' + N + 'Menu']: 'Új metódus',
            [ns + 'Menu']: 'Metódusok',
            ['deleted' + Ns + 'Menu']: 'Törölt metódusok',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt metódusok',
            ['protection' + Ns + 'Menu']: 'Védett metódusok',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'Az új metódus mentésre került',
            ['save' + N + 'SuccessMessage']: 'A metódus mentésre került',
            ['delete' + N + 'Success']: 'A metódus törölve lett',
            ['delete' + Ns + 'Success']: 'A metódusok törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a metódust?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a metódusoket?',
            ['ban' + N + 'Success']: 'A metódus véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a metódust véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A metódus jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a metódust',
            ['protection' + N + 'Success']: 'A metódus védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a metódust?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek metódusok'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Metódus',
            [ns + 'Title']: 'Metódusok',
            ['new' + N + 'Title']: 'Új metódus',
            ['edit' + N + 'Title']: 'Metódus szerkesztése',
            ['user' + Ns + 'Title']: 'Metódusok',
            ['userDeleted' + Ns + 'Title']: 'Törölt metódusok',
            ['my' + Ns + 'Title']: 'Metódusaim',
            ['myDeleted' + Ns + 'Title']: 'Törölt metódusaim',

            ['dialogDelete' + N + 'Title']: 'Metódus törlése',
            ['dialogBan' + N + 'Title']: 'Metódus törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Metódus jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Metódus védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Metódus védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Metódusok törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt metódusok',
            ['deleted' + Ns + 'Title']: 'Törölt metódusok',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett metódusok',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Metódusok kiválasztása',
            ['select' + N]: 'Metódus kiválasztása'
        }
    }
}
