import React from 'react'

import DefaultComponent from './DefaultComponent'
import DefaultArchiveComponent from './DefaultArchiveComponent'

export default function getComponent(p = {}) {

    const {
        context,
        appContext,
        name = 'post',
        archiveComponentProps = {},
        componentProps = {},
        Component = DefaultComponent,
        ArchiveComponent = DefaultArchiveComponent,
        ...rest
    } = p

    const n = name
    const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'

    const { routes } = appContext

    const { res } = context
    const route = res.wappResponse.route
    const requestPath = route.requestPath

    return (requestPath.startsWith(routes[ns + 'Route'])) ?
        (props) =>
            <ArchiveComponent
                {...rest}
                {...archiveComponentProps}
                {...props}
                name={name}
            /> :
        (requestPath.startsWith(routes[n + 'Route'])) ?
            (props) =>
                <Component {...rest}
                           {...componentProps}
                           {...props}
                           name={name}
                />
            : null

}
