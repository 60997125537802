import getStatusManager from './status'
import getConstants from './constants'
import setContents, { adminMenu, requestForUserPage, userPageFunctions } from './contents'
import getComponent from './component'

const postTypeConfig = {
    getStatusManager,
    getConstants,
    setContents,
    requestForUserPage,
    getComponent,
    userPageFunctions,
    adminMenu
}

export default postTypeConfig
