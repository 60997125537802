import { runPostTypesConfigSync } from '../../postTypes'

const menus = {
    homeMenu: 'Főoldal',
    backMenu: 'Vissza',
    themeControlsMenu: 'Téma színei',
    ...runPostTypesConfigSync({ action: 'getConstants', rKey: 'menus' }).reduce((a, v) => {
        return { ...a, ...v }
    }, {})
}

export default menus
