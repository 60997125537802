import React, { lazy, Suspense } from 'react'

const Lazy = lazy(() => import('./OrderStatusSync').catch((error) => {
    console.log('[APP] Component Failed Loading:', error)
    return { default: () => <div /> }
}))

export default (props) => {
    return (
        <Suspense fallback={<div></div>}>
            <Lazy {...props} />
        </Suspense>
    )
}
