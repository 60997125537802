import commonPostTypeConfig from '../../common/postTypes/job'

export default function initPostType({ wapp }) {
    return wapp.client.postTypes.getPostType({
        name: 'job',
        addIfThereIsNot: true,
        statusManager: commonPostTypeConfig.getStatusManager(),
        addKeys: ['jobRequestFullData']
    })
}
