import capitalize from '../../utils/capitalize'

export function getProfessionMenu({ parentRoute = '', utils, appContext, name }) {

    const N = capitalize(name)

    const professions = utils.getGlobalState('res.responses.professions')

    const menuItems = (professions?.items.length ? professions.items.filter((menu) => menu.showOnMenu) : [])

    return (menuItems.length ? menuItems.filter((menu) => !menu.parentProfessions?.length) : [])
        .map((profession) => {
            return {
                label: profession.title,
                itemsHeading: {
                    label: appContext.menus[name + capitalize(profession.slug) + 'ProfessionAll'] ||
                        (appContext.menus['get' + N + 'ProfessionAll'] ? appContext.menus['get' + N + 'ProfessionAll']({ profession }) : '') ||
                        profession.subtitle ||
                        profession.title,
                    href: parentRoute + '/professions/' + profession.slug
                },
                items: [
                    ...menuItems.filter((submenu) => {
                        const parentProfessions = submenu.parentProfessions ? submenu.parentProfessions.map((parentProfession) => parentProfession?._id || parentProfession) : []
                        return parentProfessions.indexOf(profession._id) > -1
                    }).map((profession) => {
                        return {
                            label: profession.title,
                            href: parentRoute + '/professions/' + profession.slug
                        }
                    })
                ]
            }
        })
}
