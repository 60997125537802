import React from 'react'

import getUtils from 'wapplr-react/dist/common/Wapp/getUtils'

import capitalize from '../../utils/capitalize'

import { runPostTypesConfig } from '../index'
import defaultPostTypeConfig from '../post'

import { transformPosts } from './Prices/transformPosts'
import PricesStyle from './Prices/PricesStyle'
import pricesClassNames from './Prices/style.css'

import getConstants from './constants'

import Content from './Content'

const postTypeConfig = {
    getStatusManager: function getStatusManager(p = {}) {
        return defaultPostTypeConfig.getStatusManager({
            ...p,
            config: {
                requiredDataForStatus: {
                    title: { type: String }
                },
                ...(p.config) ? p.config : {}
            }
        })
    },
    getConstants: getConstants,
    setContents: function setContents(p = {}) {

        const { wapp } = p

        const name = 'product'
        const n = name
        const N = capitalize(n)

        const r = defaultPostTypeConfig.setContents({
            ...p,
            name,
            urlParams: [
                '/page/:pagination',
                '/sort/:sort',
                '/limit/:limit'
            ]
        })

        let reqUserForPost = null

        const slugPattern = /^[a-z\d](-?[a-z\d]){1,150}$/
        const objectIdPattern = /^[\da-fA-F]{24}$/

        const contentProps = wapp.contents.get(name)

        wapp.contents.handle = async function defaultHandle(req, res, next) {
            if (!res.wappResponse.content) {
                const contentRes = wapp.contents.contentManager.resolve
                const content = await contentRes({ route: res.wappResponse.route, wapp, req, res })
                if (!res.wappResponse.content) {
                    res.wappResponse.content = content
                }
            }
            return next()
        }

        wapp.contents.add({
            [name]: {
                ...contentProps,
                request: async function({ wapp, req, res }) {

                    const wappResponse = res.wappResponse

                    const route = wappResponse.route
                    const { params } = route
                    const { _id } = params

                    const isSlugRequest = !_id?.match(objectIdPattern) && _id?.match(slugPattern)
                    const requestName = _id?.match(objectIdPattern) ? name + 'FindById' : isSlugRequest ? name + 'FindBySlug' : null

                    const statusManager = wapp.getTargetObject().postTypes.findPostType({ name: n }).statusManager
                    await runPostTypesConfig({
                        action: 'requestFor' + N + 'Page',
                        p: { wapp, req, res, statusManager }
                    })

                    if (requestName) {

                        const posts = [wappResponse.store.getState('res.responses.' + name + 'FindById'), wappResponse.store.getState('res.responses.' + name + 'FindBySlug')]
                        const post = posts.find((p) => isSlugRequest ? p?.slug === _id : p?._id === _id)

                        const args = isSlugRequest ? { slug: _id } : { _id }

                        let response

                        if (
                            (
                                (reqUserForPost?._id !== req.wappRequest.user?._id) ||
                                (_id && !post) ||
                                (!isSlugRequest && post?._id !== _id) ||
                                (isSlugRequest && post?.slug !== _id)
                            )
                        ) {
                            reqUserForPost = { _id: req.wappRequest.user?._id }
                            response = await wapp.requests.send({ requestName, args, req, res })
                        } else {
                            response = {
                                [requestName]: post
                            }
                        }

                        return response

                    }
                }
            }
        })
        return r
    },
    requestForUserPage: async function requestForUserPage(p = {}) {
        return await defaultPostTypeConfig.requestForUserPage({
            ...p,
            name: 'product'
        })
    },
    getComponent: function(p) {

        const name = 'product'
        const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'

        const { context, appContext } = p
        const { routes /*userStatusManager*/ } = appContext

        const { res, req } = context
        const route = res.wappResponse.route
        const requestPath = route.requestPath
        const user = req.wappRequest.user

        const isAdmin = user && user._status_isFeatured

        if (
            (requestPath.startsWith(routes[name + 'Route'])) ||
            (requestPath.startsWith(routes[ns + 'Route']))
        ) {

            const pages = {
                content: Content
            }

            if (requestPath === routes[name + 'Route'] + '/new' && !isAdmin && user) {
                return defaultPostTypeConfig.getComponent({
                    ...p,
                    name,
                    componentProps: { pages, getPageName: () => 'notFound' }
                })
            }

            if (requestPath.startsWith(routes[ns + 'Route']) && !isAdmin) {
                //return null
            }

            const requestKeys = [name + 'FindById', name + 'FindBySlug']
            const slugPattern = /^[a-z\d](-?[a-z\d]){1,150}$/
            const objectIdPattern = /^[\da-fA-F]{24}$/

            const utils = getUtils(context)
            const listDataObject = utils.getGlobalState('res.graphql.query.' + name + 'FindMany.listData')
            const listData = listDataObject.list
            const tableData = listDataObject.table

            listData.subtitle = {
                show: true
            }

            listData.contentExtract = {
                show: true
            }

            return defaultPostTypeConfig.getComponent({
                ...p,
                name,
                archiveComponentProps: {
                    selectable: false,
                    beforeSetPosts: ({ posts }) => {
                        return transformPosts({ posts, classNames: pricesClassNames })
                    },
                    tableData,
                    listData,
                    className: pricesClassNames.postsComponent,
                    BeforePostsComponent: PricesStyle,
                    MenuItemProps: {
                        ContentPaperComponent: ({ children, className }) => <div className={className}>{children}</div>,
                        ContentExtractComponent: ({ children, className }) => <div
                            className={className}>{children}</div>
                    }
                },
                componentProps: {
                    pages,
                    showAuthor: ({ user }) => user?._status_isFeatured,
                    //showCreatedDate: ({ user, author }) => user?._id && user._id === author?._id,
                    getProps: ({ pageName }) => {

                        if (pageName === 'content') {

                            return {
                                showAuthor: () => false,
                                PaperComponentProps: {
                                    color: undefined,
                                    elevation: 0,
                                    style: {
                                        padding: 0
                                    }
                                },
                                TitleComponentProps: {
                                    variant: 'h1'
                                }
                            }

                        }

                        return {}
                    },
                    requestKeys,
                    getInitialResponse: function() {

                        const wappResponse = res.wappResponse
                        const route = wappResponse.route
                        const { params } = route
                        const { _id } = params

                        const isSlugRequest = !_id?.match(objectIdPattern) && _id?.match(slugPattern)
                        const requestName = _id?.match(objectIdPattern) ? name + 'FindById' : isSlugRequest ? name + 'FindBySlug' : null

                        if (requestName) {
                            const posts = [wappResponse.store.getState('res.responses.' + name + 'FindById'), wappResponse.store.getState('res.responses.' + name + 'FindBySlug')]
                            return posts.find((p) => isSlugRequest ? p?.slug === _id : p?._id === _id)
                        }

                        return null

                    }
                }
            })
        }
    },
    userPageFunctions: function(p) {

        const { context, postContext } = p

        const n = 'product'
        const ns = (n.endsWith('y')) ? n.slice(0, -1) + 'ies' : n + 's'
        const N = capitalize(n)
        const Ns = capitalize(ns)

        const r = defaultPostTypeConfig.userPageFunctions({
            ...p,
            name: n,
            nsPageProps: {
                selectable: false,
                type: 'list',
                disablePageInfo: true,
                ...p.nsPageProps ? p.nsPageProps : {}
            }
        })

        const { res } = context

        const wappResponse = res.wappResponse
        const route = wappResponse.route
        const { params } = route
        const { pageType } = params

        //const {userStatusManager} = appContext;

        function addMenuItems(props) {

            const { appContext /*statusManager*/ } = props
            const { menus, routes /*userStatusManager*/ } = appContext

            return [
                {
                    label: function(p) {
                        const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                    },
                    role: function(p) {
                        const viewerIsAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                        const viewerIsAdmin = (p.user?._id && p.user._status_isFeatured)
                        const postIsAdmin = (p.post && p.post._status_isFeatured)
                        return (postIsAdmin && (viewerIsAuthor || viewerIsAdmin))
                    },
                    order: 102,
                    items: [
                        {
                            label: menus['new' + N + 'Menu'],
                            href: routes[n + 'Route'] + '/new',
                            role: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                const isAdmin = p.user && p.user._status_isFeatured
                                return !!(isAdmin && isAuthor)
                            },
                            disableParentRoute: true
                        },
                        {
                            label: function(p) {
                                const isAuthor = ((p.user?._id && p.user?._id === p.post?._author) || (p.user?._id && p.user?._id === p.post?._author?._id))
                                return (isAuthor) ? menus['my' + Ns + 'Menu'] : menus[postContext.name + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] : routes[postContext.name + Ns + 'Route']
                            },
                            role: function(p) {
                                return ((p.user && p.user._status_isFeatured) || (p.post && p.post._status_isFeatured))
                            }
                        },
                        {
                            divider: true,
                            role: function(p) {
                                return p.user && p.user._status_isFeatured
                            }
                        },
                        {
                            label: function() {
                                return menus['deleted' + Ns + 'Menu']
                            },
                            href: function(p) {
                                return (p.post?._id) ? '/' + p.post._id + routes[postContext.name + Ns + 'Route'] + '/deleted' : routes[postContext.name + Ns + 'Route'] + '/deleted'
                            },
                            role: function(p) {
                                const isAuthor = ((p?.user?._id && p?.user?._id === p?.post?._author) || (p?.user?._id && p?.user?._id === p?.post?._author?._id))
                                const isAdmin = p.user && p.user._status_isFeatured
                                return isAuthor || isAdmin
                            }
                        }
                    ]
                }
            ]
        }

        function addContentMenuItems() {
            return []
        }

        function getPageName({ user, post, page /*statusManager*/ }) {

            const isAdmin = user && user._status_isFeatured
            const isAuthor = ((user?._id && user._id === post?._author) || (user?._id && user._id === post?._author?._id))
            const isAuthorOrAdmin = (isAdmin || isAuthor)
            const authorIsAdmin = post && post._status_isFeatured

            return (
                (page === ns && !pageType) ||
                (page === ns && !isNaN(Number(pageType)) && Number(pageType) >= 1) ||
                (page === ns && pageType === 'deleted' && isAuthorOrAdmin) ||
                (page === ns && pageType === 'banned' && isAdmin)
            ) ? (isAdmin || authorIsAdmin) ? page : 'notFound' : null

        }

        return {
            ...r,
            addMenuItems,
            addContentMenuItems,
            getPageName
        }
    },
    adminMenu: function(p) {
        const name = 'product'
        const defaultAdminMenu = defaultPostTypeConfig.adminMenu({ ...p, name })
        defaultAdminMenu.order = 102
        return defaultAdminMenu
    }
}

export default postTypeConfig
