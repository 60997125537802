import defaultPostTypeConfig from '../post'
import capitalize from '../../utils/capitalize'

export default function getConstants(p = {}) {

    const name = 'transaction'

    const n = name
    const N = capitalize(n)
    const ns = (name.endsWith('y')) ? name.slice(0, -1) + 'ies' : name + 's'
    const Ns = capitalize(ns)

    const {
        labels,
        menus,
        messages,
        routes,
        titles
    } = defaultPostTypeConfig.getConstants({
        ...p,
        name
    })

    return {
        labels: {
            ...labels,
            ['new' + N + 'SubmitLabel']: 'Tranzakció indítása',
            ['restore' + N + 'SubmitLabel']: 'Visszaállítás',
            ['save' + N + 'SubmitLabel']: 'Válassz egy vásárlást',
            ['cancel' + N + 'Text']: 'Mégsem',
            ['delete' + N + 'Text']: 'Törlés',
            ['delete' + Ns + 'Text']: 'Tranzakciók törlése',
            ['ban' + N + 'Text']: 'Tiltás',
            ['approve' + N + 'Text']: 'Jóváhagyás',
            ['protection' + N + 'Text']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Text']: 'Védettség megszüntetése',
            ['select' + N + 'Text']: 'Kiválasztás',
            ['add' + N + 'Text']: 'Hozzáadás',
            ['date' + N + 'Format']: ({ dateText = '' }) => {
                if (!dateText) {
                    return dateText
                }
                try {
                    return new Date(dateText).toLocaleString('hu-HU')
                } catch (e) {
                }
                return dateText
            },
            [ns + 'SortRelevance']: 'Relevancia',
            [n + 'TitleLabel']: 'Tranzakció típusa',

            [n + 'SearchLabel']: 'Keresés tranzakció vagy rendelés azonosító alapján',
            [n + 'SearchLabelForAdmin']: 'Tranzakció azonosító, rendelés azonosító vagy felhasználó szókeresés'
        },
        menus: {
            ...menus,
            ['user' + Ns + 'Menu']: 'Tranzakciók',
            ['my' + Ns + 'Menu']: 'Tranzakcióim',
            ['new' + N + 'Menu']: 'Tranzakció indítása',
            [ns + 'Menu']: 'Tranzakciók',
            ['deleted' + Ns + 'Menu']: 'Törölt tranzakciók',
            ['banned' + Ns + 'Menu']: 'Véglegesen törölt tranzakciók',
            ['protection' + Ns + 'Menu']: 'Védett tranzakciók',
            ['missingData' + Ns + 'Menu']: 'Hiányzó adatok',
            ['lowAuthorStatus' + Ns + 'Menu']: 'Szerző alacsony státusszal',

            ['edit' + N + 'Menu']: 'Szerkesztés',
            ['delete' + N + 'Menu']: 'Törlés',
            ['ban' + N + 'Menu']: 'Tiltás',
            ['approve' + N + 'Menu']: 'Jóváhagyás',
            ['protection' + N + 'Menu']: 'Védettnek jelölés',
            ['removeProtection' + N + 'Menu']: 'Védettség törlése'
        },
        messages: {
            ...messages,
            ['new' + N + 'SuccessMessage']: 'A tranzakció mentésre került',
            ['save' + N + 'SuccessMessage']: 'A tranzakció mentésre került',
            ['delete' + N + 'Success']: 'A tranzakció törölve lett',
            ['delete' + Ns + 'Success']: 'A tranzakciók törölve lettek',
            ['delete' + N + 'Question']: 'Biztosan törli a tranzakciót?',
            ['delete' + Ns + 'Question']: 'Biztosan törli a tranzakcióket?',
            ['ban' + N + 'Success']: 'A tranzakció véglegesen törölve lett',
            ['ban' + N + 'Question']: 'Biztos, hogy a tranzakciót véglegesen törölni akarod?',
            ['approve' + N + 'Success']: 'A tranzakció jóvá lett hagyva',
            ['approve' + N + 'Question']: 'Biztosan jóváhagyod a tranzakciót',
            ['protection' + N + 'Success']: 'A tranzakció védettnek jelölve',
            ['protection' + N + 'Question']: 'Biztosan védettnek jelölöd a tranzakciót?',
            ['removeProtection' + N + 'Success']: 'A védettnek jelölés törölve lett',
            ['removeProtection' + N + 'Question']: 'Biztosan törlöd a védettnek jelölést?',
            ['thereAreNo' + Ns]: 'Nincsenek tranzakciók'
        },
        routes: {
            ...routes
        },
        titles: {
            ...titles,
            [n + 'Title']: 'Tranzakció',
            [ns + 'Title']: 'Tranzakciók',
            ['new' + N + 'Title']: 'Tranzakció indítása',
            ['edit' + N + 'Title']: 'Tranzakció szerkesztése',
            ['user' + Ns + 'Title']: 'Tranzakciók',
            ['userDeleted' + Ns + 'Title']: 'Törölt tranzakciók',
            ['my' + Ns + 'Title']: 'Tranzakcióim',
            ['myDeleted' + Ns + 'Title']: 'Törölt tranzakcióim',

            ['dialogDelete' + N + 'Title']: 'Tranzakció törlése',
            ['dialogBan' + N + 'Title']: 'Tranzakció törlése véglegesen',
            ['dialogApprove' + N + 'Title']: 'Tranzakció jóváhagyása',
            ['dialogProtection' + N + 'Title']: 'Tranzakció védettnek jelölése',
            ['dialogRemoveProtection' + N + 'Title']: 'Tranzakció védettségének megszüntetése',

            ['dialogDelete' + Ns + 'Title']: 'Tranzakciók törlése',

            ['statusBanned' + N + 'Title']: 'Véglegesen törölt',
            ['statusDeleted' + N + 'Title']: 'Törölt',
            ['statusAuthorDeleted' + N + 'Title']: 'Szerző törölve',
            ['statusMissingData' + N + 'Title']: 'Hiányzó adatok',
            ['statusApproved' + N + 'Title']: 'Jóváhagyott',
            ['statusFeatured' + N + 'Title']: 'Védett',
            ['statusCreated' + N + 'Title']: 'Létrehozva',

            ['banned' + Ns + 'Title']: 'Véglegesen törölt tranzakciók',
            ['deleted' + Ns + 'Title']: 'Törölt tranzakciók',
            ['missingData' + Ns + 'Title']: 'Hiányzó adatok',
            ['protected' + Ns + 'Title']: 'Védett tranzakciók',
            ['lowAuthorStatus' + Ns + 'Title']: 'Szerző alacsony státusszal',

            ['select' + Ns]: 'Tranzakciók kiválasztása',
            ['select' + N]: 'Tranzakció kiválasztása'
        }
    }
}
